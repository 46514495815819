import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TEXT } from '../../../theme';
import ArrowRightGrey from '../../../assets/svg-bid/arrow-right-grey.svg';
import CurrencyLogo from '../../CurrencyLogo';
import axios from 'axios';
import { createChart, LineData, Time } from 'lightweight-charts';
import { useCurrency } from '../../../hooks/Tokens';
import { useHistory } from 'react-router';
import { chartOptions, setAreaOptions } from './chart-config';
import { useFindTokenAddress } from '../../../state/swap/hooks';
import ChartSm from '../../../assets/images/chartSm.png';
import Thr from '../../../assets/images/tather.png';
import Eth from '../../../assets/images/eth.png';
import Media from '../../../theme/media-breackpoint';

// OLD SCOMPONENTS
const Wrapper = styled.tr<{ growth: boolean }>``;

const RowItem = styled.div`
  display: flex;
`;

const Spacing = styled.div<{ ml: number }>`
  margin-left: ${({ ml }) => ml && ml + 'px'};
`;

const GraphContainer = styled.div`
  width: 100%;
  height: 80px;
  margin-top: 15px;
`;

interface DataType {
  time: number;
  high: number;
  low: number;
  open: number;
  volumefrom: number;
  volumeto: number;
  close: number;
  conversionType: string;
  conversionSymbol: string;
}

interface Data {
  TimeFrom: number;
  TimeTo: number;
  Aggregated: boolean;
  Data: DataType[];
}

interface Response2 {
  Response: string;
  Message: string;
  HasWarning: boolean;
  Type: number;
  Data: Data;
}

interface Props {
  currency1: string;
  currency2: string;
}

const width = 240;
const height = 80;

export function GraphComponent({ currency1, currency2 }: Props) {
  const container = useRef<HTMLDivElement | null>(null);
  const canFetch = useRef<boolean>(true);
  const chartWasCreated = useRef<boolean>(false);

  const [dailyData, setDailyData] = useState<LineData[]>([]);
  const [currencyPrice, setCurrencyPrice] = useState<number>(0);
  const [hasGrown, setHasGrown] = useState<boolean>(false);

  const address1 = useFindTokenAddress(currency1);
  const address2 = useFindTokenAddress(currency2);

  const addressOrEth1 = currency1 !== 'NRK' ? address1 : 'NRK';
  const addressOrEth2 = currency2 !== 'NRK' ? address2 : 'NRK';

  const currencyA = useCurrency(addressOrEth1);
  const currencyB = useCurrency(addressOrEth2);

  const history = useHistory();

  const fetchDailyData = async () => {
    if (!canFetch.current) {
      return;
    }
    try {
      const { data } = await axios.get<Response2>(
        `https://min-api.cryptocompare.com/data/v2/histoday?fsym=${currency1.toUpperCase()}&tsym=${currency2.toUpperCase()}&limit=20`
      );
      const result: LineData[] = data.Data.Data.map((item) => ({ time: item.time as Time, value: item.open }));
      setDailyData(result);
      canFetch.current = false;
      if (result.length) {
        const isGrowth = result[0].value < result[result.length - 1].value;
        setHasGrown(isGrowth);
      }
    } catch (err) {
      console.debug('Fetch daily data', err);
    }
  };

  const fetchPrice = async () => {
    try {
      const { data } = await axios.get(
        `https://min-api.cryptocompare.com/data/price?fsym=${currency1.toUpperCase()}&tsyms=${currency2.toUpperCase()}`
      );
      setCurrencyPrice(data[currency2]);
    } catch (err) {
      console.debug('Fetch token price for graph', err);
    }
  };

  const navigateToSwap = () => {
    history.push(`/swap?inputCurrency=${addressOrEth1}&outputCurrency=${addressOrEth2}`);
  };

  useEffect(() => {
    fetchDailyData();
    fetchPrice();
  }, []);

  if (!dailyData || dailyData.length === 0) {
    return null;
  }

  if (container.current && !chartWasCreated.current) {
    const chart = createChart(container.current, chartOptions);
    chart.resize(width, height);
    const lineSeries = chart.addAreaSeries(setAreaOptions(hasGrown));
    lineSeries.setData(dailyData);
    chart.timeScale().fitContent();
    chartWasCreated.current = true;
  }

  const growth = ((dailyData[dailyData.length - 1].value - dailyData[0].value) / dailyData[0].value) * 100;

  return (
    <>
      {/* <TableContainer growth={hasGrown} onClick={navigateToSwap}> */}
      <Wrapper onClick={navigateToSwap} growth={hasGrown}>
        <td data-title="Exchange">
          <b className="coins">
            <CurrencyLogo style={{ width: '25px', height: '25px' }} currency={currencyA ? currencyA : undefined} />
            <CurrencyLogo style={{ width: '25px', height: '25px' }} currency={currencyB ? currencyB : undefined} />
          </b>
          {currency1} - {currency2}
        </td>
        <td data-title="24-Hours Statics" color={hasGrown ? 'primary3' : '#E63449'}>
          <TEXT.default fontWeight={400} fontSize={20} color={hasGrown ? 'primary3' : '#E63449'}>
            {growth.toFixed(2)}%
          </TEXT.default>
        </td>
        <td data-title="Market State">
          <div className="chart">
            <GraphContainer ref={container}></GraphContainer>
          </div>
        </td>
        <td data-title="Volume">
          1 {currency1} = <br />
          {currencyPrice} {currency2}
        </td>
      </Wrapper>
      {/* </TableContainer> */}
    </>
  );
}

//  NEW SCOMPONENTS
const TableContainer = styled.div<{ growth: boolean }>`
  background: #fff;
  border-radius: 10px;
  max-width: calc(100% - 40px);
  position: relative;
  z-index: 2;
  &:after {
    content: '';
    background: #bae1d8;
    position: absolute;
    top: 0;
    left: 50vw;
    margin-left: 250px;
    height: 500px;
    width: 500px;
    border-radius: 100%;
    z-index: -1;
    filter: blur(50px);
    opacity: 0.4;
  }
  table {
    width: 100%;
    th {
      text-align: left;
      padding: 0 30px;
      font-size: 20px;
      background: #e8f9f5;
      height: 60px;
      font-weight: 600;
      &[align='center'] {
        text-align: center;
      }
    }
    td {
      padding: 0 30px;
      font-size: 20px;
      height: 60px;
      height: 100px;
      border-bottom: 1px solid #e5e5e5;
      span {
        color: #e13046;
      }
      .chart {
        display: table;
        margin: 0px auto;
      }
      .coins {
        margin-right: 16px;
        img {
          margin-right: -8px;
          width: 25px;
          height: 25px;
          object-fit: contain;
        }
      }
    }
  }
  ${Media.sm} {
    table {
      display: block;
      thead {
        display: none;
      }
      tr {
        border-bottom: 1px solid #e5e5e5;
        padding: 10px 0;
        display: flex;
        flex-flow: wrap;
        td {
          display: block;
          width: 50%;
          height: auto;
          border-bottom: 0;
          font-size: 18px;
          padding: 10px 30px;
          &:before {
            content: attr(data-title);
            font-weight: 600;
            display: block;
            margin-bottom: 5px;
          }
          .chart {
            margin: 0;
          }
        }
      }
    }
  }
  ${Media.xs} {
    margin-top: 80px;
    table {
      tr {
        td {
          width: 100%;
          padding: 12px 20px;
          .chart {
            width: 100%;
          }
        }
      }
    }
  }
`;
const TbTop = styled.div`
  display: flex;
  width: 100%;
  padding: 22px 35px;
  h3 {
    font-size: 35px;
    margin: 0;
    font-weight: 600;
  }
  .TbTop-right {
    margin-left: auto;
    display: flex;
    align-items: center;
    div > img {
      margin-top: 5px;
    }
    h4 {
      margin: 0;
      font-size: 20px;
      color: var(--primary);
      font-weight: 600;
    }
    p {
      margin: 0;
      color: var(--txtLight);
      font-size: 16px;
    }
    a {
      width: 30px;
      height: 30px;
      background: var(--primary);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 0 4px rgba(27, 193, 154, 0.2);
      margin-left: 14px;
      flex-shrink: 0;
    }
  }
  ${Media.md} {
    h3 {
      font-size: 28px;
    }
  }
  ${Media.sm} {
    border-bottom: 1px solid #e5e5e5;
    flex-flow: column;
  }
  ${Media.xs} {
    padding: 22px 20px;
    h3 {
      font-size: 25px;
      margin-bottom: 20px;
    }
    .TbTop-right {
      flex-flow: column;
      align-items: flex-start;
      div > img {
        margin: 0 0 0 -10px;
      }
      a {
        margin: 10px 0 0;
      }
    }
  }
`;
