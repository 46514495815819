import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Gs from '../../theme/globalStyles';
import Media from '../../theme/media-breackpoint';
import logo2 from '../../assets/images/Nordek/Group 5.svg';
import twitter from '../../assets/images/twitter.png';
import discord from '../../assets/images/discord.png';
import telegram from '../../assets/images/telegram.png';
import Tooltip from '../Tooltip';
import Norswap_icon from '../../assets/images/Nordek/Footer_logo.png';
import Facebook from '../../assets/images/Nordek/Facebook.png';
import Instagram from '../../assets/images/Nordek/Instagram.png';
import Telegram from '../../assets/images/Nordek/Telegram.png';
import Twitter from '../../assets/images/Nordek/Twitter.png';

import Youtube from '../../assets/images/Nordek/Youtube.png';

interface SpacedBlockProps {
  marginLeft?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
}

interface IconLinProps {
  text: string;
  url: string;
  icon: typeof twitter;
}

// OLD SCOMPONENTS
const FooterFrame = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  z-index: 2;
  flex-direction: column;
  background-color: #101010;
  font-family: 'Nunito Sans';

`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2.5rem;
`;

const FooterRow = styled.div`
  display: flex;
  align-items: center;
`;

const TopSection = styled.div`
  padding: 40px 8.5rem 1.5rem;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  border-top: 1px solid ${({ theme }) => theme.newTheme.border};
`;

const SpacedBlock = styled.div<SpacedBlockProps>`
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  text-decoration: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  :hover {
    opacity: 0.7;
  }
`;

const links: IconLinProps[] = [
  // { text: 'Facebook', url: '/', icon: FacebookIcon },
  {
    text: 'Twitter',
    url: 'https://twitter.com/officialnordek',
    icon: twitter,
  },
  { text: 'Discord', url: 'https://discord.gg/HWgDCEvR2z', icon: discord },
  { text: 'Telegram', url: 'https://t.me/officialnordek', icon: telegram },
];

// NEW SCOMPONENTS

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const FooterMain = styled.div`
  background: #101010;
  color: #fff;
  padding: 40px 0;
  font-family: 'Nunito Sans';
  ${Gs.Container} {
    justify-content: space-between;
  }
  .fooLeft {
    width: 347px;
    img {
      width: 230px;
    }
    p {
      font-size: 18px;
      line-height: 1.4;
      margin: 20px 0 0;
    }
  }
  .fooLink {
    h4 {
      font-size: 24px;
      margin: 0 0 24px 0;
      font-weight: 600;
      &:after {
        content: '';
        width: 30px;
        height: 2px;
        background: var(--primary);
        display: table;
        margin: 6px 0 0 3px;
      }
    }
    a {
      display: table;
      font-size: 18px;
      margin: 0 0 20px 0;
      &:hover {
        color: #6239C6;
      }
      img {
        margin: 4px 4px 0 0;
        width:24px;
      }
    }
  }
  ${Media.md} {
    ${Gs.Container} {
      max-width: 100%;
    }
  }
  ${Media.sm} {
    .fooLeft {
      width: 100%;
      order: 3;
      margin-top: 30px;
    }
    .fooLink {
      order: 1;
    }
  }
`;
const ButtonSm = styled.a`
  background: linear-gradient(to bottom, #4200CD, #8C00CD);
  box-shadow: -5px 5px 0px #fff;
  padding: 0 26px;
  margin: 0 auto;
  height: 45px;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  padding:10px;
  margin-top:10px;
  font-weight: 600;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  line-height: 1;
  font-family: 'Nunito Sans';
  img {
    margin-right: 6px;
  }
  &.lg {
    width: 100%;
    & + & {
      margin-top: 20px;
    }
  }
  a{
    color:white
  }

  &.sm {
    font-size: 16px;
    padding: 7px 14px;
    height: auto;
    letter-spacing: -0.01em;
  }
  &:hover {
    background: #6239C6;
  }
  &.secondary {
    background: none;
    border: 1px solid var(--txtLight2);
    color: var(--txtLight);
    &:hover {
      border: 1px solid var(--txtColor);
      background: #6239C6;
      color: #fff;
    }
  }
  ${Media.lg2} {
    padding: 0 20px;
  }
  ${Media.md} {
  }
`;

export default function Footer() {
  return (
    <FooterFrame>
      <FooterMain>
        <Gs.Container>
          <div className="fooLeft">
            <a href="/">
              <img src={Norswap_icon} />
            </a>
            <p>
            Trading crypto assets has high opportunities and risks. Make sure you use good judgment in making buying
              and selling decisions on your assets. {' '}
            </p>
            {/* <ButtonSm>
                <a href='#'>Buy $NRK</a>
              </ButtonSm> */}

            <p>© Copyright 2024 | All Rights Reserved.</p>
          </div>
          <div className="fooLink">
            <h4>Links</h4>
            <a style={{ color: 'white' }} href="/service">
              Terms of Services
            </a>
            <a style={{ color: 'white' }} href="mailto:bidelity@yahoo.com">
              Support
            </a>
            <a style={{ color: 'white' }} href="/faq">
              FAQ
            </a>
          </div>
          <div className="fooLink">
            <h4>Contact us</h4>
            {links.map(({ text, icon, url }, index) => (
              <a style={{ color: 'white' }} key={index} href={url}>
                <img src={icon} alt={text} /> {text}
              </a>
            ))}
          </div>
        </Gs.Container>
      </FooterMain>
    </FooterFrame>
  );
}

const IconLink = ({ text, icon, url }: IconLinProps) => {
  const [show, setShow] = useState<boolean>(false);

  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);
  return (
    <SpacedBlock>
      <Tooltip show={show} text={text} placement="bottom-start">
        <ExternalLink target="_blank" href={url} onMouseEnter={open} onMouseLeave={close}>
          <img width="34px" height="34px" src={icon} alt={text} />
        </ExternalLink>
      </Tooltip>
    </SpacedBlock>
  );
};
