import { Percent } from '@bidelity/sdk';
import React from 'react';
import { ONE_BIPS } from '../../constants';
import { warningSeverity } from '../../utils/prices';
import { ErrorText } from './styleds';
import { TEXT } from '../../theme';

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({ priceImpact }: { priceImpact?: Percent }) {
  return (
    <ErrorText fontWeight={500} fontSize={14} severity={warningSeverity(priceImpact)}>
      <span>{priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}</span>
    </ErrorText>
  );
}
