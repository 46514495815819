import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TEXT } from '../../theme';
import axios from 'axios';
import Eth from '../../assets/images/eth.png';
import Media from '../../theme/media-breackpoint';
import OwlCarousel from 'react-owl-carousel-rtl';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CurrencyLogo from 'components/CurrencyLogo';
import NordekIcon from '../../assets/images/Nordek/Nrklogo.png'

interface TokenProps {
  name: string;
  price: number;
  change: number;
}

interface TokenPrices {
  [key: string]: {
    usd: number;
    usd_24h_change: number;
  };
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TokenItemWrapper = styled.div`
  padding: 0 10px;
  display: flex;
`;

const PriceItem = styled.div`
  margin-left: 5px;
`;

const tokens =
  'nordek,wrapped-bitcoin,usd-coin,shiba-inu,tether,coinbase-wrapped-staked-eth,matic-network,uniswap,dai,binance-usd,hex,yearn-finance,quant-network,gnosis,maker';

const url = `https://api.coingecko.com/api/v3/simple/price?ids=${tokens}&vs_currencies=usd&include_24hr_change=true`;

const TOKEN_NAMES = {
  nordek: 'NRK',
  'wrapped-bitcoin': 'WBTC',
  'usd-coin': 'USDC',
  'shiba-inu': 'SHIB',
  tether: 'USDT',
  'coinbase-wrapped-staked-eth': 'WETH',
  'matic-network': 'MATIC',
  uniswap: 'UNI',
  dai: 'DAI',
  'binance-usd': 'BUSD',
  hex: 'HEX',
  'yearn-finance': 'YFI',
  'quant-network': 'QNT',
  gnosis: 'GNO',
  maker: 'MKR',
};

type TockensIds =
  | 'nordek'
  | 'wrapped-bitcoin'
  | 'usd-coin'
  | 'shiba-inu'
  | 'tether'
  | 'coinbase-wrapped-staked-eth'
  | 'matic-network'
  | 'uniswap'
  | 'dai'
  | 'binance-usd'
  | 'hex'
  | 'yearn-finance'
  | 'quant-network'
  | 'gnosis'
  | 'maker';

export default function PricesRow({ tokensPrices }: { tokensPrices: TokenPrices }) {
  // const [tokensPrices, setTokenPrices] = useState<TokenPrices | null>(null);

  let keys: TockensIds[] = [];
  if (tokensPrices) {
    keys = Object.keys(tokensPrices) as TockensIds[];
  }
  return (
    <>
      {tokensPrices !== null &&
        keys.length !== 0 &&
        keys.map((key, index) => (
          <Fragment key={key}>
            <TokenItem
              name={TOKEN_NAMES[key]}
              price={tokensPrices[key].usd}
              change={tokensPrices[key].usd_24h_change}
            />
          </Fragment>
        ))}
    </>
  );
}

const TokenItem = ({ name, price, change }: TokenProps) => {
  const isRed = change.toString().includes('-');
  
  return (
    <div className="tag">
      <img src={NordekIcon} alt="eth" />
      {/* <CurrencyLogo currency={currency} size={'33px'} /> */}
      {name}
      <TEXT.default
        style={{
          fontFamily: "'Barlow ', sans-serif",
          fontSize: 16,
          marginLeft: '8px',
        }}
        fontWeight={600}
        fontSize={12}
        color={isRed ? 'error' : 'primary3'}
      >
        ${price}
      </TEXT.default>
    </div>
  );
};
