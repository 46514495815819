import { CFC_TOKEN_ADDRESS } from '../index';

export const DEFAULT_TOKENS_LIST = {
  current: {
    name: 'Compound',
    logoURI: 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/compound-interface.svg',
    keywords: ['compound', 'defi'],
    timestamp: '2021-05-27T20:37:00.000+00:00',
    tokens: [
      {
        name: 'Wrapped Nordek',
        address: '0x56d5a28e8417fF7d9D3E2C6216Eb2A4902209Ab9',
        symbol: 'WNRK',
        decimals: 18,
        chainId: 81041,
        logoURI:
          'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xd0A1E359811322d97991E03f863a0C30C2cF029C/logo.png',
      },
      {
        chainId: 58875,
        address: '0x8e1575a778FF412030bF1a6366938e592D1d8dC8',
        name: 'wusdt',
        symbol: 'wUSDT',
        decimals: 6,
        logoURI: 'https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397',
      },
      {
        chainId: 81041,
        address: '0xe3deAA063803C6F4d7B9fEcF346dd8CeEE115Db3',
        name: 'Starnest',
        symbol: 'SNT',
        decimals: 18,
        logoURI:
          'https://raw.githubusercontent.com/kashyap222884/assets/main/blockchains/nrk/0xe3deAA063803C6F4d7B9fEcF346dd8CeEE115Db3/logo.png',
      },
      {
        chainId: 81041,
        address: '0x97827Ce6e746786d0cD6D12Dcb8Fda1Be007d14f',
        name: 'Kuromi',
        symbol: 'Kuromi',
        decimals: 18,
        logoURI: 'https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397',
      },
      {
        chainId: 58875,
        address: '0xceBa082c764292e475A026BD3ED7cF89369c94cF',
        name: 'Test Token',
        symbol: 'MDT',
        decimals: 18,
        logoURI: 'https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397',
      },
      {
        chainId: 58875,
        address: '0x5476659703Fe19285dAb78F9551cb6910269824a',
        name: 'Test 3',
        symbol: 'TT3',
        decimals: 18,
        logoURI: 'https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397',
      },
    ],
    version: {
      major: 2,
      minor: 0,
      patch: 0,
    },
  },
  pendingUpdate: null,
  loadingRequestId: null,
  error: null,
};
